import React, { useState } from "react";
import styles from "../templates/eventDetail.module.scss";
const EventNewsLetter = () => {
  const [showSubscribeMessage, setShowSubscribeMessage] = useState(false);
  const [subscribeMessage, setSubscribeMessage] = useState("");
  const [subscribeSubMessage, setSubscribeSubMessage] = useState("");
  const [subscribeStatus, setSubscribeStatus] = useState(null);

  const handleSubmit = async e => {
    clearSubscriptionValues();
    e.persist();
    e.preventDefault();
    let json = {};

    new FormData(e.target).forEach((value, key) => {
      json[key] = value;
    });
    const data = json;
    var object = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: object,
      redirect: "follow",
    };
    fetch(
      `${process.env.GATSBY_API_BASE_URL}/addNewsLetterSubscription`,
      requestOptions
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (parsedData) {
        if (parsedData.message.includes("Already")) {
          displaySubscribeMessage("exists");
        } else {
          displaySubscribeMessage("success");
        }
      })
      .catch(error => {
        displaySubscribeMessage("error");
      });
  };

  function displaySubscribeMessage(message) {
    if (message === "success") {
      setSubscriptionValue(
        "Thank you!",
        "Your subscription is successfully completed, Please check your e-mail",
        "success"
      );
    } else if (message === "exists") {
      setSubscriptionValue(
        "Email already in use",
        "Please use a different e-mail id",
        "error"
      );
    } else if (message === "error") {
      setSubscriptionValue(
        "Error while subscribing",
        "Please try after some time",
        "error"
      );
    }

    setShowSubscribeMessage(true);
  }

  function clearSubscriptionValues() {
    setSubscriptionValue("", "", null);
    setShowSubscribeMessage(false);
  }

  function setSubscriptionValue(message, subMessage, status) {
    setSubscribeMessage(message);
    setSubscribeSubMessage(subMessage);
    setSubscribeStatus(status);
  }
  return (
    <section className={styles.eventNewsLetterContainer}>
      <div>
        <h4 className={`font-20 ${styles.newsletterHeader}`}>
          Join Our Newsletter
        </h4>
        <p className={` font-16 ${styles.newsSubtitle}`}>
          Get the insights on latest events and get togethers in your area
        </p>
        <form className="container" onSubmit={handleSubmit}>
          <div className="row">
            <div
              className={`form-group mx-0 row col-12 col-md-12 mb-4 mb-md-4 px-0 ${styles.formGroup}`}
            >
              <input
                className="form-control "
                type="text"
                id="email"
                name="email"
                placeholder="Email Address"
                required
              ></input>
            </div>
          </div>
          <div className="row">
            <button
              className={`btn button-outline-8BC540 font-16  mx-0 col-12 col-md-12 mb-md-4 ${styles.subscribeBtn} font-16 fw-500 letter-spacing-019 text-uppercase`}
            >
              Subscribe Now
            </button>
          </div>
        </form>
        {showSubscribeMessage && (
          <div className={`text-center ${styles.subscribeContainer}`}>
            <h4
              className={`${
                subscribeStatus === "error" ? "text-FF0000" : "text-8BC540"
              } ${styles.subscribeTitle}`}
            >
              {subscribeMessage}
            </h4>
            <p className={`${styles.subscribeSubtitle} mb-0`}>
              {subscribeSubMessage}
            </p>
          </div>
        )}
      </div>
    </section>
  );
};
export default EventNewsLetter;
