import { graphql } from "gatsby";
import React from "react";
import EventsSection from "../components/events";
import NgoSection from "../components/ngoSection";
import RegisterEvent from "../components/registerEvent";
import { Layout } from "../components/layout";
import OrganisersSection from "../components/organisers";
import EventNewsLetter from "../components/eventNewsLetter";
import styles from "./eventDetail.module.scss";
const EventDetail = props => {
  const downloadFile = () => {
    window.location.href = props.data
      ? contentfulEvents.downloadAgenda.file.url
      : "";
  };
  const eventsArray = props.data.allContentfulEvents.nodes;
  const contentfulEvents = props.data.contentfulEvents;
  return (
    <Layout>
      <div className="position-relative row col-12 mx-0 px-0">
        <div className={`col-12 text-white ${styles.carouselContainer} px-0`}>
          <div
            className={styles.carouselImage}
            style={{
              backgroundImage: ` linear-gradient(to bottom right, rgba(0,0,0,0.6), transparent) ,
                  linear-gradient(to bottom left, rgba(0,0,0,0.6), transparent),
                  url("${
                    props.data
                      ? contentfulEvents.eventBannerImage.fluid.src
                      : ""
                  }")`,
            }}
          ></div>
          <div className={styles.captionContainer}>
            <h3
              className={`font-44 font-weight-bolder mb-4 mb-md-4 ${styles.captionTitle}`}
            >
              {props.data ? contentfulEvents.eventBannerImage.title : ""}
            </h3>

            <button
              className={`btn button-outline-8BC540 button-bg-translucent-dark ${styles.captionButton} font-16 fw-500 letter-spacing-019 text-uppercase`}
              onClick={downloadFile}
              id="download-agenda"
            >
              Download Agenda
            </button>
          </div>
        </div>

        <RegisterEvent
          eventDate={props.data ? contentfulEvents.eventDate : ""}
          registrationLink={props.data ? contentfulEvents.registrationLink : ""}
          registrationbuttontext={
            props.data ? contentfulEvents.registrationButtonText : ""
          }
          eventregistration={
            props.data ? contentfulEvents.eventRegistration : ""
          }
        />
        <OrganisersSection />
        <div
          className={`row  mx-1 mb-4 mb-md-4 mt-2  ml-0  ${styles.mapDonorRow}`}
        >
          <div className="col-12 col-lg-8">
            <h3 className={`mb-4 mb-md-4  ${styles.eventHeader}`}>
              Event Information
            </h3>
            <p className={`font-20  ${styles.eventDescription}`}>
              {props.data
                ? contentfulEvents.eventDescription.eventDescription
                : ""}
            </p>
          </div>
          <div className="col-12 col-lg-4 px-0">
            <EventNewsLetter />
          </div>
        </div>
        <EventsSection event={eventsArray} />

        <NgoSection />
      </div>
    </Layout>
  );
};

export default EventDetail;

export const query = graphql`
  query($slug: String!) {
    allContentfulEvents {
      nodes {
        eventSlug
        eventTitle
        subtitle
        eventDate(formatString: "DD/MMM")
        eventImage {
          fluid {
            src
            srcSet
            srcSetWebp
            srcWebp
            sizes
          }
          file {
            url
          }
          description
          title
        }
      }
    }
    contentfulEvents(eventSlug: { eq: $slug }) {
      eventSlug
      eventTitle
      subtitle
      eventDate(formatString: "DD/MMM")
      eventRegistration
      registrationLink
      registrationButtonText
      downloadAgenda {
        file {
          url
        }
      }
      eventDescription {
        eventDescription
      }
      eventBannerImage {
        fluid {
          src
          srcSet
          srcSetWebp
          srcWebp
          sizes
        }
        file {
          url
        }
        description
        title
      }
      eventImage {
        fluid {
          src
          srcSet
          srcSetWebp
          srcWebp
          sizes
        }
        file {
          url
        }
        description
        title
      }
    }
  }
`;
