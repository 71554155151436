import React from "react";

import styles from "./registerEvent.module.scss";

export const RegisterEvent = props => {
  const registerHere = () => {
    window.location.href = props.registrationLink;
  };
  return (
    <section className={styles.eventRegistrationContainer}>
      <div>
        <div className={`mb-4 mb-md-4 ${styles.eventIconContainer} `}>
          <img
            src="/icons/icn-event.png"
            alt="event"
            className={`${styles.eventicon}`}
          />
        </div>
        <h3 className={` font-26  ${styles.eventDate}`}>
          {props.eventDate} IST
        </h3>
        <p className={` font-20 ${styles.eventlocation}`}>at Hadapsar, Pune </p>

        <button
          className={`btn button-outline-8BC540 font-16  ${styles.captionButton} font-16 fw-500 letter-spacing-019 text-uppercase `}
          onClick={registerHere}
        >
          {props.registrationbuttontext}
        </button>
      </div>
    </section>
  );
};

export default RegisterEvent;
